import axios from 'axios';

const axiosInstance = axios.create({

  baseURL: 'https://api.pbrpx.com', // 设置你的 API 的基本 URL
  //baseURL: 'http://192.168.2.111', // 设置你的 API 的基本 URL
  timeout: 5000, // 设置请求超时时间
  withCredentials: true // 允许发送身份验证凭证（例如，使用 Cookie 或授权头部）
});

export default axiosInstance;