<script setup lang="ts">
import { RouterLink, RouterView } from 'vue-router'
import service       from "../Plugin/axiosInstance.js"
import Card from '@/components/Card.vue'
import Any_Loding from '@/components/Loding.vue'
import Asset_details from '@/components/Asset_details.vue'
import MenuItem from '@/components/MenuItem.vue'
</script>

<template>
  <div class="grid-container">
  	<!-- 头部内容 -->
    <div class="title">
      <div class="logo" style="background-size: auto 70%;;background-repeat: no-repeat;background-position: center;">
        <a href="https://pbrpx.com">
          <img class="im_title" src="/pbrpx.webp" >
        </a>
      </div>

      <div class="search_container2">
        <div class="search_input" :class='{inpfocus: inp_focus}'>
          <div class="search_icon">
              <i class="bi bi-search " ></i>
          </div>
          <div class="input_container">
            <input type="input" ref='search_inp' name="cc" placeholder="Search..." @blur='on_blur' @focus='inp_focus = true ' v-model='search_input_val' @keyup.enter='enterTosearch($event)'>
          </div>
          <div class="search_icon close" @click = 'clearTosearch()'>
              <i class="bi bi-x-lg"></i>  
          </div>
        </div>
      </div>

      <div class="SUPPORT_US">
        <a class='SUPPORT_US_BTN' href='https://www.patreon.com/PBRPX'>SUPPORT US</a>
      </div>
    </div>
    <!-- 搜索 -->
    <div class="search_container">

    </div>
    <!-- 搜索end -->
    <!-- 左侧导航 -->
    <div class="sidebar">
      <div class="navs">
          <menu-item :menuData="navTreeObject" :selectedIds="selectedIds" ></menu-item>
      </div>
    </div>
    <!-- 导航end -->

    <div class="Social">
      <a href="https://www.facebook.com/profile.php?id=61555868871366" class="Social_a"><i class="bi bi-facebook"></i></a>
      <a href="https://www.instagram.com/pbrpx_cgi" class="Social_a"><i class="bi bi-instagram"></i></a>
      <a href="https://qm.qq.com/q/B6StD8LaF2" class="Social_a"><i class="bi bi-tencent-qq"></i></a>
      <a href="https://twitter.com/pbrpxcom" class="Social_a" ><i class="bi bi-twitter-x"></i></a>
      <a href="https://www.artstation.com/pbrpx" class="Social_a" ><i class="bi art graypic"></i></a>
    </div>

    <!-- 卡片内容 -->
    <div ref="scrollContainer" class="main" v-infinite-scroll="get_product" :infinite-scroll-distance= '1000' :infinite-scroll-delay='500' :infinite-scroll-immediate='false' >
      <el-row :gutter="20">
        <template v-for='i in product_list'>
          <Card :id='i.id' :image='i.img_url' :isnew='is_new(i.create_time)' :name='i.ename' :paramsJson='paramsJson'/>
        </template>
      </el-row>
      <Any_Loding v-if='loading' />
      <section class="product_section_content" v-else-if='product_list.length==0'>
          <div class="product_section_pv" v-if='!loading'>
              <span>SORRY, NO MATCHES WERE FOUND</span>
          </div>
      </section>
    </div>
  </div>
  <Asset_details :is_show='asset_show' :data='asset_data' @windows_show='windows_show' />

</template>
<script lang="ts">


export default {
      name: 'Asset',
    data() {
        return {
            /*产品属性*/
            inp_focus: false,
            search_input_val: '',
            product_list: [],
            page_number :1,
            totalPages:0,
            loading:false,
            asset_show: false,
            asset_data: [],
            k_list: [],
            navObject:{},
            navTreeObject_copy:[],
            selectedIds:[],
            navTreeObject:[],
            paramsJson:{}/*当前的网页参数*/
        }
    },
    created() {

    },
    methods: {
      setWithExpiry: function(key, value, expiryTimeInSeconds) {
        const now = new Date();
        const item = {
          value: value,
          expiry: now.getTime() + expiryTimeInSeconds * 1000
        };
        localStorage.setItem(key, JSON.stringify(item));
      },
      enterTosearch(event){
        if(this.inp_focus){
          this.inp_focus = false
          var url = new URL(window.location.href);
          // 获取 URL 中的参数
          var searchParams = new URLSearchParams(url.search);
          // 将参数转换为 JSON 对象
          var paramsJson = {};
          for (var pair of searchParams.entries()) {
            var key         = pair[0];
            var value       = pair[1];
            paramsJson[key] = value;
          }
          if(this.search_input_val!==''){
            paramsJson.search = this.search_input_val
            this.$router.push({ path: '/asset', query: paramsJson})
          }
          
          this.$refs.search_inp.blur()
        }
      },
      getHashParams() {
        const hash = window.location.hash.substr(1); // 去掉开头的 #
        const params = {};
        
        if (hash) {
            const parts = hash.split('?'); // 以 ? 分割
            const path = parts[0]; // 获取路径部分
            params.path = path; // 存储路径

            if (parts.length > 1) {
                const queryString = parts[1]; // 获取查询参数部分
                const paramPairs = queryString.split('&');

                paramPairs.forEach(pair => {
                    const [key, value] = pair.split('=');
                    params[key] = decodeURIComponent(value || ''); // 解码参数值
                });
            }


        }
        delete params['path']
        
        return params;
      },
      clearTosearch(){
          var url = new URL(window.location.href);
          // 获取 URL 中的参数
          var searchParams = new URLSearchParams(url.search);
          // 将参数转换为 JSON 对象
          var paramsJson = {};
          for (var pair of searchParams.entries()) {
            var key         = pair[0];
            var value       = pair[1];
            paramsJson[key] = value;
          }
          delete paramsJson.search
          this.$router.push({ path: '/asset', query: paramsJson})
          this.$refs.search_inp.blur()
      },
      on_blur(){
        this.inp_focus = false
      },
      inp_rt(){
        if(inp_focus){
          return true
        }else{
          return false
        }
      },
      getWithExpiry: function(key) {
        const itemString = localStorage.getItem(key);
        if (!itemString) {
          return null;
        }
        const item = JSON.parse(itemString);
        const now = new Date();
        if (now.getTime() > item.expiry) {
          localStorage.removeItem(key);
          return null;
        }
        return item.value;
      },
      check_router() {
        this.is_router = !this.is_router
      },
      /*窗口开关*/
      windows_show() {
        this.asset_show = !this.asset_show
        if(! this.asset_show){
          let paramsJson = this.getHashParams()
          delete paramsJson['asset']
          // console.log(paramsJson)
          this.$router.push({ path: '/asset', query: paramsJson})
        }
      },
      scrollToTop() {
        this.$refs.scrollContainer.scrollTop = 0;
      },
      get_product(is_clear = false){
        let obj = this.paramsJson/*当前网页的参数*/
        let _this = this
        // console.log('_this.totalPages:',_this.totalPages,'_this.page_number:',_this.page_number)   
        if (_this.totalPages < _this.page_number && _this.totalPages != 0) {
            return false
        }
        let data = {
          page_number: this.page_number
        }

        if( 'category' in obj){
          data.category = obj.category
          
        }
        if( 'search' in obj){
          data.search = obj.search
          
        }
        if(_this.loading){
          return false
        }
        _this.loading = true
        service.post('/home/api_product/getPmsg', data).then((res) => {
          // console.log(res)
            this.totalPages = res.data.data.totalPages
            if(is_clear){
              _this.product_list = res.data.data.data
            }else{
              _this.product_list = _this.product_list.concat(res.data.data.data)
            }
            _this.page_number  = _this.page_number+1
            _this.loading      = false
        });
      },
      findParentNodes(jsonData) {
        const nodeIndex = {};
        const result = [];
        for (const node of jsonData) {
          const nodeId = node.id;
          const parentId = node.pid;
          if (!nodeIndex[nodeId]) {
            nodeIndex[nodeId] = { children: [] };
          }
          nodeIndex[nodeId] = { ...node, children: nodeIndex[nodeId].children };
          const currentNode = nodeIndex[nodeId];
          if (parentId !== 0) {
            if (!nodeIndex[parentId]) {
              nodeIndex[parentId] = { children: [] };
            }
            nodeIndex[parentId].children.push(currentNode);
          } else {
            result.push(currentNode);
          }
        }
        return result;
      },
      addIsOpenProperty(data) {
        for (const item of data) {
          item.is_close = true;
          if (item.children && item.children.length) {
            this.addIsOpenProperty(item.children);
          }
        }
      },
      getnavdata(obj,is_reset = false) {
        let _this = this
        /*调用缓存*/
        if(!is_reset){
          if(_this.getWithExpiry('navObject')){
            _this.navTreeObject = _this.findParentNodes(_this.getWithExpiry('navObject'))
            let sum =  _this.navTreeObject.reduce((accumulator, currentValue) => {
            const count = parseInt(currentValue.product_count);
              if (!isNaN(count)) {
                return accumulator + count;
              }
              return accumulator;
            }, 0);
            _this.navTreeObject.unshift({
              "id": -1,
              "type_name": "All",
              "class": 0,
              "pid": 0,
              "product_count": sum,
              "children": [],
              "is_close": true
            })
            _this.addIsOpenProperty( _this.navTreeObject)
            if('category' in obj){
              _this.selectedIds = _this.findParentNodeIds(_this.navTreeObject,Number(obj.category))
            }
            return
          }
        }
        /*重新请求数据*/
        service.post('/home/api_product/getNav').then(function(res) {
          _this.navObject     = res.data.data
          _this.navObject.unshift(1)
          _this.setWithExpiry('navObject',_this.navObject, 300)
          _this.navTreeObject = _this.findParentNodes(_this.navObject)
          // console.log(_this.navTreeObject)
          _this.navTreeObject_copy = _this.navTreeObject
          let sum =  _this.navTreeObject.reduce((accumulator, currentValue) => {
            const count = parseInt(currentValue.product_count);
            if (!isNaN(count)) {
              return accumulator + count;
            }
            return accumulator;
          }, 0);
          _this.navTreeObject.unshift({
                "id": -1,
                "type_name": "All",
                "class": 0,
                "pid": 0,
                "product_count": sum,
                "children": [],
                "is_close": true
      
          })
          _this.addIsOpenProperty( _this.navTreeObject)

          if('category' in obj){
            _this.selectedIds = _this.findParentNodeIds(_this.navTreeObject,Number(obj.category))
          }
        })
      },
      /*返回所有的父id*/
      findParentNodeIds(treeItems, nodeId, parentIds = []) {
        for (const item of treeItems) {
          if (item.id === nodeId) {
            return parentIds.concat(item.id);
          }
          if (item.children && item.children.length > 0) {
            const result = this.findParentNodeIds(item.children, nodeId, [...parentIds, item.id]);
            if (result) {
              return result;
            }
          }
        }
        return null;
      },
      get_asset(id) {
        let _this = this
        let arr = []
        let arr2 = []
        service.post('/home/api_product/getasset', { asset: id }).then(function(res) {
          _this.asset_data = res.data.data
          // console.log(res.data.data)
          _this.windows_show()
        })
      },
      is_new(time_str){
        // 获取当前时间
        var currentDate = new Date();

        // 将时间字符串转换为日期对象
        var targetDate = new Date(time_str);

        // 计算时间差（以毫秒为单位）
        var timeDiff = Math.abs(currentDate - targetDate);

        // 将时间差转换为天数
        var daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

        // 判断是否在15天以内
        if (daysDiff <= 20) {
          
          return true
        } else {
          // console.log('时间超过15天');
          return false
        }

      }

    },
    watch: {
      $route:function(val1,val2){
        
         
        let asset_in1     = 'asset'    in val1.query
        let category_in1  = 'category' in val1.query
        let asset_in2     = 'asset'    in val2.query
        let category_in2  = 'category' in val2.query
        let search_in1    = 'search'   in val1.query
        let search_in2    = 'search'   in val2.query
        this.paramsJson = val1.query
        if(asset_in1){
          this.get_asset(val1.query.asset)
        }else{
          this.asset_show = false
        }

        if (search_in1){
          if (val1.query.search != val2.query.search){
            
            this.search_input_val =  val1.query.search
            this.page_number=1
            
            this.get_product(true)
            
            // 获取容器元素
            this.scrollToTop()
          }else if (val1.query.search == val2.query.search){
            
          }else{
            this.search_input_val = ''
            
          }
        }else if(val1.query.search != val2.query.search && !search_in1){
          this.search_input_val = ''
          this.page_number=1
          this.scrollToTop()
          this.get_product(true)
        }

        if(category_in1){
          if(val1.query.category !== val2.query.category ){
            this.getnavdata(val1.query)
            this.page_number=1

            this.get_product(true)
            // 获取容器元素
            this.scrollToTop()
          }
        }else if (val1.query.category == undefined && val2.query.category !== undefined){
            
            this.getnavdata(val1.query)
            this.page_number=1
            this.get_product(true)
            // 获取容器元素
            this.scrollToTop()
            this.selectedIds = []
        }

      }
    },
    mounted() {
      
      var url = new URL(window.location.href);
      // 获取 URL 中的参数
      var searchParams = new URLSearchParams(url.search);
      // 将参数转换为 JSON 对象
      var paramsJson = this.getHashParams();

      // for (var pair of searchParams.entries()) {
      //   var key = pair[0];
      //   var value = pair[1];
      //   paramsJson[key] = value;
      // }
      
      if('category' in paramsJson){
         this.selectedId = Number(paramsJson.category)
      }
      if ('search' in paramsJson){
          this.search_input_val =  paramsJson.search
      }

      if('asset' in paramsJson){
        this.get_asset(paramsJson.asset)
      }else{
        this.asset_show = false
      }

      this.paramsJson = paramsJson
      this.getnavdata(paramsJson,true)
      this.get_product()
    }
}
</script>

<style scoped>
  .graypic { 
    
    -webkit-filter: grayscale(125%);
    -moz-filter: grayscale(125%);
    -ms-filter: grayscale(125%);
    -o-filter: grayscale(125%);
    filter: grayscale(125%);
    filter: gray;
}
    .Social_a{
      margin: 0.5rem;
      color: #DBDBDB;
    }
    .art{
      background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSczNScgaGVpZ2h0PSczMicgZmlsbD0nbm9uZSc+PHBhdGggZmlsbD0nIzEzQUZGMCcgZmlsbC1ydWxlPSdldmVub2RkJyBkPSdNMzUgMjQuMzU0YzAtLjcwNC0uMjA4LTEuMzYtLjU2NS0xLjkxTDIyLjkzNyAyLjUyNUEzLjUzNiAzLjUzNiAwIDAgMCAxOS44MTMuNjUyaC02LjA3N2wxNy43NiAzMC42NjYgMi44LTQuODMzYy41NTMtLjkyNS43MDQtMS4zMzQuNzA0LTIuMTMxWm0tMzUtLjAzNyAyLjk1NiA1LjA5M2guMDAxYTMuNTM2IDMuNTM2IDAgMCAwIDMuMTU3IDEuOTM4aDE5LjYyNGwtNC4wNzItNy4wM0gwWk0xMC44MzIgNS42MjFsNy45MzggMTMuNzAxSDIuODkzbDcuOTM5LTEzLjcwMVonIGNsaXAtcnVsZT0nZXZlbm9kZCcvPjwvc3ZnPg==);
       filter: gray;
       filter: brightness(150%);
       -webkit-filter: brightness(150%);
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      height:17px;
      width: 26px;
    }
    body{
      padding: 0;
      margin: 0;
      background-color: white;
    }
    .navs{
        display: grid;
        grid-template-rows: 1fr; /* 网格行高度设置为1份可用空间 */
        padding: 12px;
    }
    .nav_nav{
      max-height: 100%;
      overflow: scroll;
    }

    .search_icon{
      font-size: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #4E4E4E;
    }
    .close{
      font-weight: 1200;
      cursor: pointer;
    }
    .search_input{
      height: 50px;
      border-radius: 50px;
      opacity: 1;
      border: 1px solid #00000000;
      background: #060709;
      display: grid;
      grid-template-columns: 50px 1fr 50px;
      /*行数*/
      grid-template-rows: 1fr;
    }
    .grid-container {
      display: grid;
      /*列数*/
      grid-template-columns: 350px 1fr;
      /*行数*/
      grid-template-rows: 80px 80px 1fr 2rem;
      grid-template-areas:
        "title title"
        "sidebar main"
        "sidebar main"
        "Social  main";
      min-height: 100vh;
      height: 100vh;
    }
    .title {
      grid-area: title;
      background-color: #232323;
      text-align: center;
      border-bottom: 1px solid #505050;
      display: grid;
      grid-template-columns: 350px 1fr 140px ;
      grid-template-areas:"logo search_container2 SUPPORT_US";
    }
    .logo img{
      height: 60px;
    }
    .Social{
      grid-area: Social;
      height: 2rem;
      background-color: #232323;
      border-right: 1px solid #505050;
      text-align: center;
      font-size: 1.2rem;
    }

    .search_container2{
      grid-area: search_container2;
      height: 80px;
      padding: 15px 20px;
      background: #232323;

    }
        .rong{
      width: 100%;
    }


    /* 设置网格布局 */




    /* 导航菜单样式 */
    .sidebar {
      grid-area: sidebar;
      background-color: #232323;
      border-right: 1px solid #505050;
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
/*      will-change: transform;*/
    }

    .search_container{
      border-right: 1px solid #505050;
      grid-area: search_container;
/*      height: 80px;*/
      border-bottom: 1px solid #505050;
/*      padding: 15px 20px;*/
      background: #232323;

    }

    .SUPPORT_US_BTN{

      font-family: 'Nunito', sans-serif !important;
      color: #fff;
      padding: 5px 20px;
      min-height: 40px;
      font-size: 13px;
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      outline: none;
      border: 0px solid transparent;
      border-radius: 0;
      box-shadow: none;
      vertical-align: middle;
      text-align: center;
      text-decoration: none;
      text-shadow: none;
      font-weight: 600;
      line-height: 1.2;
      cursor: pointer;
      border-radius: 35px;
      background: #38acff;
      transition: color .25s ease, background-color .25s ease, border-color .25s ease, box-shadow .25s ease, opacity .25s ease;
    }

    .SUPPORT_US_BTN :hover{
      color: #ffffff;
      background: #38acff !important;
      border: 0px;
    }

    .product_section_pv{
      text-align: center;
      margin-top:10rem ;
      font-size: 2.5rem;
    }


    /* 主要内容样式 */
    .main {
      grid-area: main;
      background-color: #232323;
      overflow-y: scroll;
      overflow-x: clip;
      flex: 1;
    /*    background-color: #242424;*/
/*      border-radius: 20px;*/
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
      will-change: transform;
      -webkit-overflow-scrolling: touch;
    }
    .logo a{
      display: contents;
    }
    .SUPPORT_US{
      grid-area: SUPPORT_US;
      display: flex;
      align-items: center;
      justify-content: left;
/*      padding-right:20px;*/
    }

    .logo{
/*      border-right: 1px solid #505050;*/
      grid-area: logo;
      letter-spacing: 0em;
      display: flex;
      align-items: center;
      justify-content: center;
/*      text-align: center;*/

    }

    .title_nav{
      margin-left: auto;
      white-space: nowrap;
      padding-right: 49px;
      line-height: 80px;
      height: 80px;
      color: white;
    }

    .title_nav span a{
      margin-left: 20px;
      font-size: 13px;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 0em;
      font-feature-settings: "kern" on;
      color: #c8c8c8;

    }
    .input_container{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      

    }
    .input_container input{
      background: #00000000;
      width: 100%;
      height: 100%;
      border:0px;
      font-size: 1.12em;
      color: white;
    }

    .pxcard{
      aspect-ratio: 1 / 1;  
    }
    
    .inpfocus{
      border: 1px solid #4e4e4e;
    }

    @media screen and (max-width: 1025px) {
      .big_nav{
        display: none;
      }
      .grid-container {
        display: grid;
        /*列数*/
        grid-template-columns: 250px 1fr;
        /*行数*/
        grid-template-rows: 80px 1fr;
        grid-template-areas:
          "title title"
          "sidebar main"
          "Social  main";
        min-height: 100vh;
        height: 100vh;
      }
      /*.im_title {
          content: url(/logo.webp);
      }*/
      .logo img{
        height: 50px;
      }
      .title {
/*        grid-area: title;
        background-color: #232323;
        text-align: center;
        border-bottom: 1px solid #505050;
        display: grid;*/
        grid-template-columns: 250px 1fr 126px ;
/*        grid-template-areas:"logo search_container2 SUPPORT_US";*/
      }
      .SUPPORT_US{
        justify-content: center;
      }


    }

    @media screen and (max-width: 700px) {
      .big_nav{
        display: none;
      }
      .grid-container {
        display: grid;
        /*列数*/
        grid-template-columns: 0px 1fr;
        /*行数*/
        grid-template-rows: 160px 1fr;
        grid-template-areas:
          "title title"
          "sidebar main"
          "Social Social";
        min-height: 100vh;
        height: 100vh;
      }
      /*图片替换*/
      /*.im_title {
          content: url(/logo.webp);
      }*/

      .title {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr 126px; 
        grid-template-areas:
        "logo logo logo" 
        "search_container2 search_container2 SUPPORT_US";
      }
      .logo{
        border-bottom: 1px solid #505050;
      }
      .search_container2{
        padding: 15px 4px;
      }
      .SUPPORT_US{
        justify-content: center;
      }
      .logo img{
        height: 61px !important;
      }

    }


</style>
