<script setup lang="ts">

</script>

<template>
    <el-col style='padding-top: 10px;padding-bottom: 10px;' :xl="4" :lg='6' :md='8' :sm='12' :xs='24' >
        <router-link  :to="'/asset?asset='+id+objectToBrowserPath(paramsJson)" >
          <div class='ca'>
            <div class="newtext">
              <span v-if='isnew'>
                NEW
              </span>
            </div>
            <div class="pxcard"  :style="background_str+select_preview(image)+background_str2">
              <div class="kong">
                
              </div>
              <div class="pxcard_name">
                {{name_retrun(name)}}
              </div>
            </div>
          </div>
        </router-link>
    </el-col>
</template>
<script lang="ts">
  export default {
    name: 'CardDefault',
    props: {
        id:Number,
        name: String,
        isnew:Boolean,
        image:String,
        paramsJson:Object
    },
    data() {
        return {
            background_str: 'background-image: url(',
            background_str2: ')',
            sta:true,
            isback:'',
            header:'https://asset.pbrpx.com/'
        }
    },
    methods: {
      
      select_preview(str) {
          let arr = str.split('+')
          let cc=''
          for(let i of arr){
              if(i.indexOf('360_360')!=-1){
                  cc = i
              }
          }
          return this.header +cc
      },
      /*将对象转化为浏览器路径*/
      objectToBrowserPath(obj) {
        const params = new URLSearchParams();
        delete params.asset
        let i=0
        let str = ''
        for (const key in obj) {
          if (obj.hasOwnProperty(key) ) {
            i++
            params.append(key, obj[key]);
          }
        }
        if(i==0){
          str = ''
        }else{
          str = '&'
        }
        return str + params.toString();
      },
      name_retrun(str) {
            str = str.replace('，', ',')
            str = str.replace('_', ' ')
            return str.split(',')[0]

      },
      is_new(time_str){
        console.log(time_str)
        // 获取当前时间
        var currentDate = new Date();

        // 将时间字符串转换为日期对象
        var targetDate = new Date(time_str);

        // 计算时间差（以毫秒为单位）
        var timeDiff = Math.abs(currentDate - targetDate);

        // 将时间差转换为天数
        var daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

        // 判断是否在15天以内
        if (daysDiff <= 15) {
          return true
        } else {
          // console.log('时间超过15天');
          return false
        }

      }
    },
    mounted() {}

}

</script>
<style scoped>
.pxcard{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    aspect-ratio: 1 / 1;  
    /*border-right: 0.2px solid #50505040 ;
    border-bottom: 0.2px solid #50505040 ;*/
  }
.kong{
  height: 87%;
}
.pxcard_name{
  font-family: 'Alibaba Sans';
  font-feature-settings: "kern" on;
  width: 100%;
  text-align: center;
  font-size: 12px;
  text-decoration: none !important;
  color: #c8c8c800;
  height: 13%;
  line-height:13%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pxcard:hover .pxcard_name {
    box-shadow: 0;
    color: #c8c8c8 !important;
    font-family: 'Alibaba Sans';
    font-feature-settings: "kern" on;
    font-size: 0.8rem;
}
.ca{
  border-radius: 15px;
}
.ca:hover{
  border-radius: 15px;
  background-color: #212121 !important;
}
.newtext{ 
/*    width: 100%;*/
    position: absolute;
    text-align: right;
    padding: 0px 1rem;
    background: #20202000!important;
    font-size: .6rem!important;
    color: #fff;
    font-weight: 600;
    font-weight: ;
    ont-style: italic;
    font-style: oblique;
}
.newtext span{
/*  background: #c1c1c1;*/
  padding: 5px;
  border-radius: 1rem;
}
</style>
