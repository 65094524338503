<template>
  <ul class="ul">
    <template v-for="item in menuData" >
    <li  class="li" v-if='item.product_count !=0 ' :key="item.id">
      <div class="px_menu">
        <router-link :to='item.id==-1? "asset": "asset?category="+item.id'>
          <div class="px_type_name"   :class="{collapsed: updateItem(item,selectedIds)}">
          <!-- <div class="px_type_name"  @click="toggleCollapse(item)" :class="{collapsed: item.is_close}"> -->
           <span v-if='item.children.length > 0'>
              <template v-if='item.is_close'>
                <i class="bi bi-chevron-right tubiao"></i>
              </template>
              <template v-else>
                <i class="bi bi-chevron-down tubiao"></i>
              </template>
           </span>
           <span v-else>
             <i class="menu_nbsp">&nbsp;&nbsp;&nbsp;&nbsp;</i>
           </span>
           <span class="tp_name" :class="{is_selected: !updateItem(item,selectedIds)}">
            {{ removeChineseAndPunctuation(item.type_name) }}
           </span>
          </div>
        </router-link>
        <div class="quantity">{{item.product_count }}</div>
      </div>
      <ul class="ul" v-if="!item.is_close && item.children && item.children.length > 0">
        <menu-item :menuData="item.children.sort((a, b) => a['type_name'].localeCompare(b['type_name']))" :selectedIds="selectedIds" ></menu-item>
      </ul>
    </li>
  </template>
  </ul>
</template>
<!-- 
  // 根据id值的首字母排序
arr.sort((a, b) => a['id'].localeCompare(b['id']))
console.log(arr)
// 根据中文的首字母排序
arr1.sort((a, b) => a.localeCompare(b))
console.log(arr1)


 -->

<script lang="ts">
export default {
  name: 'MenuItem',
  props: {
    menuData: {
      type: Array,
      required: true
    },
    selectedIds: {
      type: Array,
      required: true
    }
  },
  methods: {
    toggleCollapse(item) {
      this.menuData2 = this.menuData2
      if (item.children.length > 0) {
        item.is_close = !item.is_close;
      }
    },
    updateItem(item,selectedIds){
      if(selectedIds.indexOf(item.id)!==-1){
        item.is_close = false
        return false
      }else{
        return true
      }
    },
    /*去除里面的中文*/
    removeChineseAndPunctuation(str) {
      return str.replace(/[\u4e00-\u9fa5,，。！？、；：“”‘’【】《》（）—]/g, '').replace('_', ' ');
    },
    closeOtherMenus(currentItem) {
      for (const item of this.menuData) {
        if (item !== currentItem) {
          item.is_close = true;
        }
      }
    },
    toggleSubMenu(item) {
      item.is_close = !item.is_close;
      if (item.is_close) {
        this.closeOtherMenus(item);
      }
    },
    expandParentMenus() {
      const selectedMenu = this.menuData.find(item => item.id === this.selectedId);
      if (selectedMenu) {
        this.expandParent(selectedMenu);
      }
    },
    expandParent(menu) {
      menu.is_close = false;
      const parentMenu = this.menuData.find(item => item.id === menu.parentId);
      if (parentMenu) {
        this.expandParent(parentMenu);
      }
    }


  }
};
</script>

<style scoped>

.collapsed {
/*  cursor: pointer;*/
  color: #C8C8C8 !important;
}
.menu_nbsp{
  width : 1.12em;
  height: 1.12em;
}
.tp_name{
  max-width: 200px;
  /*display: inline-block;
  white-space: nowrap;
  overflow: hidden;*/
  text-overflow: ellipsis;
}

.px_type_name{
  font-size: 1em;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
  cursor: pointer;
  font-feature-settings: "kern" on;
  color: #C8C8C8 !important;
  text-overflow: ellipsis;
}

/*.px_type_name span{

}*/

.ul{
    padding-left:10px !important ;
    list-style-type:none;
    width: 100%;
    /*padding: 0px !important;
    margin: 0px!important;*/
}
/*.li{
  padding: 0px 5px !important ;
}*/
.px_menu{
  display: grid;
  grid-template-columns: 1fr 55px;
  padding: 3px 0px !important;
      /*行数*/
}

.quantity {
  float: right;
  font-size: 0.8em;
  border-radius: 25px;
  background: #373535;
  border: 1px solid #787878;
  text-align: center;
  color: #C8C8C8;
}
.tubiao{
  color: #C8C8C850;
}

.is_selected{
  font-weight: 800;
  color:#dbdbdb ;
}
@media screen and (max-width: 1025px) {
  .quantity{
    display: none;
  }
  .px_menu span{
/*    display: inline-block;
    white-space: nowrap;*/
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

</style>