<template>
    <div class="windows" v-if='is_show' @click.stop='close_windows()'>
        <div class="windows_content" @click.stop='return'>
            <div class="windows_content_container">
                <div class="left">
                    <div class="tuijian" style='padding: 15px 15px;text-align: center;'>
                        Similar Assets
                    </div>
                    <el-row style="overflow-y: scroll;padding: 0px 0px !important;margin: 0px!important">
                        <el-col :span="24" style='padding: 0px 0px;margin: 0px;' v-for='Recommend in data[0].recommend'>
                            <!--  -->
                            <router-link target='_blank'  :to="'/asset?'+'&asset='+Recommend.id">
                                <div :style="'height:160px;width:160px;background-size: contain;background-image: url('+ select_preview(img_retruns(Recommend.img_url)[0]) +'); opacity: 1;'">
                                </div>
                            </router-link>
                        </el-col>
                    </el-row>
                </div>
                <div class="center" v-if='!drawer'>
                    <div class="perview_container" :style="' background-image: url('+ select_preview(img_retrun(data[0].img_url)[img_index]) +'); opacity: 1;'">
                        <template v-if='!v_3d'>
                            <template v-if='img_retrun(data[0].img_url).length>1'>
                                <div class="qiehuan" >
                                    <span @click='previous_img(img_retrun(data[0].img_url).length)'>
                                        <i class="bi bi-chevron-compact-left"></i>
                                    </span>
                                </div>
                                <div class="xiabaio">
                                    <div ></div>
                                    <div style="color: #c8c8c8">{{img_index+1}}/{{img_retrun(data[0].img_url).length}} </div>
                                </div>
                                <div class="qiehuan">
                                    <span @click = 'next_img(img_retrun(data[0].img_url).length)'>
                                        <i class="bi bi-chevron-compact-right"></i>
                                    </span>
                                </div>
                            </template>
                            
                        </template>
                        <!-- <template v-if='v_3d'>
                            <Renderer resize='true' orbitCtrl>
                            <Camera :position="{ z: 15 }" />
                            <Scene background='#232323'>
                              <AmbientLight    :intensity='0.9' />
                              <PointLight :position="{ x: 120, z: 0 }" :intensity='1.5' />
                              <GltfModel @load="onReady" :src="gitf_url" />
                            </Scene>
                            </Renderer>
                        </template> -->
                    </div>
                    <div class="asset_bottom">
                        <div class="asset_size">
                            <div class="kk">
                                <div class="asset_size_webp" v-if='data[0].description.indexOf("EVs")==-1&&data[0].description!=""'>
                                    <img v-if='isXY(data[0].description)'  src="../assets/xy.webp" style="opacity:0.6">
                                    <img v-else  src="../assets/xyz.webp"  style="opacity:0.6">
                                </div>
                                <div class="asset_size_webp" style="font-size: 1.2rem;"  v-if='data[0].description=="32EVs"||data[0].description=="16EVs"' >
                                    HDRI
                                </div>
                                <div class="asset_size_webp" style="font-size: 1.2rem;" v-if='data[0].description==""' >
                                    IMAGES
                                </div>
                                <div class="asset_size_text">
                                    {{data[0].description}}
                                </div>
                            </div>
                        </div>
                        <div class="asset_msg">
                            <div class="ename">
                                {{language_retrun(data[0].ename)}}
                            </div>
                            <div class="downloads">
                                Downloads:{{data[0].download_count}}
                                <a ref='da' :href="download_url"></a>
                            </div>
                        </div>
                        <div class="asset_download">
                            <div class="_download" v-if='data[0].resolutions'>
                                <div class="file_select">
                                    <el-select class="m-2"  placeholder="8K Resolution" effect='dark' size="small" v-model='Resolution'>
                                        <template v-for='k in arr_meihua(data[0].resolutions.split(","))'>
                                            <el-option v-if='k!=""'  :key="k.replace('K','')" :label="k.replace('K','')+'K Resolution'" :value="Number(k.replace('K',''))" />
                                        </template>
                                    </el-select>
                                </div>
                                <div>
                                    <div v-if='data[0].description.indexOf("EVs")==-1'  class='down_set'  type="primary" @click="drawer = true" ><i class="bi bi-gear-fill" style="font-size: 1.2rem;"></i></div>
                                    <div v-else  class='down_set_disab'  type="primary" ><i class="bi bi-gear-fill" style="font-size: 1.2rem;cursor: not-allowed;"></i></div>
                                </div>
                            </div>
                            <div class="_download">
                                <el-button  class='down_btn' :loading="download_loding" type="primary" @click='download(data[0].id)'>{{DOWNLOAD_text}}</el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="setting" v-if='drawer'>
                    <div class="down_options">
                        DOWNLOAD OPTIONS
                    </div>
                    <div @click='drawer=false' style="padding:1rem;cursor: pointer;">
                        <i class="bi bi-chevron-compact-left"></i><span >BACK</span>
                    </div>
                    <div style='padding: 1rem;'>
                        <template v-for='(i,k) in assets'>
                            <el-row>
                                <el-col :span="6" :xs='2'><div ></div> </el-col>
                                <el-col :span="6" :xs='10'><div >{{k}}:</div> </el-col>
                                <el-col :span="6" :xs='12'>
                                    <span style="padding-left: 2rem;">
                                        <span><el-checkbox v-model="assets[k]['jpg']" @change='uv()'> JPG </el-checkbox></span> <span><el-checkbox v-model="assets[k]['exr']" @change='uv()'> EXR </el-checkbox></span>
                                    </span>
                                </el-col>
                                <el-col :span="6" :xs='0'><div ></div> </el-col>
                            </el-row>
                        </template>
                    </div>
                </div>
                <div class="windows_close" @click='close_windows()'>
                    <svg class="crossIcon___3Onif" width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L15 15M15 1L1 15" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </div>
            </div>
        </div>
    </div>
<!-- <el-button  type="primary" style="margin-left: 16px;">
  点我打开
</el-button> -->

<!-- <el-drawer
  title="我是标题"
  :visible.sync="drawer"
  :with-header="false">
  <span>我来啦!</span>
</el-drawer> -->

</template>
<script lang="ts">
import service from "../Plugin/axiosInstance.js"
//import copy from 'vite-plugin-copy'
// copy([{ src: 'node_modules/downzip/dist/downzip-sw.js', dest: '.' }])
import axios from 'axios'
import DownZip from 'downzip'
const downZip = new DownZip()
// import {AmbientLight , RectAreaLight, Box, Camera, LambertMaterial, PointLight, Renderer, Scene ,GltfModel,FbxModel} from 'troisjs';


export default {
    name: 'Asset_details',
    props: {
        is_show: Boolean,
        data: Object,
        ziplist: Array
    },
    // components: {AmbientLight ,RectAreaLight, Box, Camera, LambertMaterial, PointLight, Renderer, Scene ,GltfModel,FbxModel},
    data() {
        return {
            gitf_url:'',
            drawer: false,
            show_3d:false,
            img_arr  : [],
            img_arr2 : [],
            img_index: 0,
            loding:false,
            url_title: '',
            v_3d:false,
            Resolution:4,
            download_loding:false,
            DOWNLOAD_text:'DOWNLOAD',
            // cj:downloadUrl,
            cc: false,
            header:'https://asset.pbrpx.com/',
            header2:'https://s3.us-west-1.wasabisys.com/asset.pbrpx.com/',
            img_render_type: '-webkit-optimize-contrast',
            p_text: '......',
            download_url: '',
            drawer: false,
            files: [],
            value: 'JPEG',
            filesobj: {},
            assets:{
                albedo      :{jpg:true,exr:false},
                normal      :{jpg:true,exr:false},
                glossiness  :{jpg:true,exr:false},
                roughness   :{jpg:true,exr:false},
                fuzz        :{jpg:true,exr:false},
                ao          :{jpg:true,exr:false},
                cavity      :{jpg:true,exr:false},
                displacement:{jpg:true,exr:true},
                specular    :{jpg:true,exr:false},
                metalness   :{jpg:true,exr:false},
                opacity     :{jpg:true,exr:false},
                translucency:{jpg:true,exr:false}
            }
        }
    },

    methods: {
        async dl(downloadId,zipName,files){
            await downZip.register()
            const zipFileName = zipName
            const filteredFiles = this.filterFiles(files, this.assets)
            console.log(filteredFiles,files)
            const downloadUrl = await downZip.downzip(
                downloadId,
                zipFileName,
                filteredFiles
            )
            
            this.download_url = downloadUrl
            let _this = this
            console.log(files)
            setTimeout(()=>{
                _this.$refs.da.click()
            },10)
        },

        checkPathForKeywords(path, keywords) {
            for (let i = 0; i < keywords.length; i++) {
                if (path.includes(keywords[i])) {
                    return true;
                }
            }
            return false;
        },

        arr_meihua(arr){
            let ar = []
            for (let i in arr ){
                if (arr[i]!=''){
                    ar.push(arr[i])
                }
            }

            ar = ar.sort(function(a, b) {
              var numberA = parseInt(a); // 提取数字部分并转换为整数
              var numberB = parseInt(b);
              return numberA - numberB;
            })
            // console.log('2222222222222222222222----------------',ar)
            return ar
        },

        uv(){
            // console.log(this.assets)
            localStorage.setItem('assets',this.assets)

            // setCookie('assets',this.assets,365)
            // let c = getCookie("assets")
            // console.log(c)
        },
        filterFiles(files, conditions) {
            let filter_arr = []
            const keywords = ["1K", "2K", "4K", "8K",'16K']
            for(let i in conditions){
                // console.log(i)
                for(let j in conditions[i]){
                    if (conditions[i][j]==true){
                        console.log(i,j)
                        for(let k in files){
                            if (files[k].name.indexOf(j)!==-1 && files[k].name.indexOf(i)!==-1 ){
                                // console.log(files[k].name)
                                filter_arr.push(files[k])
                            }

                        }

                    }
                }
            }
            for(let i in files){
                if( !this.checkPathForKeywords(files[i].downloadUrl, keywords) ){
                    filter_arr.push(files[i])
                }
            }
            return filter_arr
        },
        onReady(e){
            console.log(e)
            this.loding= false
            // e.traverse(o=>{
            //     if(o.isMesh){
            //         const asArray =  Array.isArray(o.material)?o.material:[o.material]
            //         asArray.forEach(mat=>(mat.matelness = 0))
            //     }
            // })
        },
        next_img(length){
            
            if (this.img_index+1 >= length){
                this.img_index =  0
            }else{
                this.img_index =  this.img_index +1
            }

        },
        checkRemoteResource(url) {
            try {
                let _this = this
                _this.show_3d = false
                var v = new XMLHttpRequest();
                // 设置请求类型为 HEAD，只获取头部信息而不需要完整内容
                v.open('HEAD', url);
                // 发送请求并处理返回结果
                v.onload = function() {
                    // console.log('v',v.status)
                    if (v.status === 200 || v.status === 304) {
                        // console.log("远程资源存在");
                        _this.show_3d = true
                    } else  {
                        _this.show_3d = false
                        // console.log("远程资源不存在或无法访问");
                    }
                    if(v.status === 404){
                        console.log(e)
                    }
                };
                // 发起请求
                v.send();
            } catch (error) {
                if (error.status === 404) {
                    console.log("404");
                } else {
                    throw error; // 其他类型的错误需要重新抛出
                }
            }
        },
        fangfa(str){
            let narr = []
            let arr = str.split(',')
            for(let i in arr){
                if (arr[i]!=''){
                    narr.push(arr[i])
                }
            }
            
            return narr
        },
        previous_img(length){
            if (this.img_index <= 0){
                this.img_index =  length -1
            }else{
                this.img_index =  this.img_index -1
            }
        },
        close_windows() {
            this.k = 2
            this.img_index = 0
            this.drawer = false
            this.$emit('windows_show')
        },
        language_retrun(str) {
            // console.log(str)
            try {
                str = str.replace('，', ',')
                str = str.replace('_', ' ')
                if (str.indexOf(',') == -1) {
                    return str
                }else{
                    return str.split(',')[0]
                }
            } catch (e) {
                // console.log(e)
            }
        },
        async download(id){
            let _this = this
            if(this.data[0].description.indexOf('EVs')==-1){
                this.download_loding = true
                this.DOWNLOAD_text = 'GETTING ASSETS'
                service.post('/home/api_product/getfilelist', { asset: id ,Resolution:_this.Resolution}).then(function(res) {
                    // console.log(res.data.data.file_list)
                    const files = res.data.data.file_list
                    _this.dl(new Date().getTime()+id,_this.data[0].url_name+'_'+_this.Resolution+'K',files)
                    _this.download_loding = false
                    _this.DOWNLOAD_text = 'DOWNLOAD'
                    _this.data[0].download_count =parseInt(_this.data[0].download_count)+1
                })
            }else{
                let cc = 'https://asset.pbrpx.com/HDRI/'+this.data[0]['url_name']+'/'+_this.Resolution+'K/'+this.data[0]['url_name']+'_'+_this.Resolution+'K.exr'
                console.log(cc)
                window.open(cc)
            }
        },
        isXY(str) {
            if (str.split('*').length == 2) {
                return true
            } else {
                return false
            }
        },
        preview_3d(){
            this.v_3d = !this.v_3d
            this.loding= true
        },
        img_retrun(data) {
            let arr = []
            let imgarr = data.split('+')
            for (let i of imgarr) {
                if (i !== '') {
                    if (i.indexOf("360_360") == -1) {
                        arr.push(i)
                    }
                }
            }
            this.img_arr = arr
            
            return arr
        },img_retruns(data) {
            let arr = []
            let imgarr = data.split('+')
            for (let i of imgarr) {
                if (i !== '') {
                    if (i.indexOf("360_360") !== -1) {
                        arr.push(i)
                    }
                }
            }
            // 
            this.img_arr2 = arr
            return arr
        },
        test(){
            // console.log('k')
        },
        select_preview(str) {
            this.is_render_px(this.header + str)
            return this.header + str
        },
        is_render_px(url) {
            let img_url = url
            // 创建对象
            let img = new Image()
            // 改变图片的src
            img.src = img_url
            let _this = this
            // 加载完成执行
            img.onload = function() {
                // 打印
                if (img.width == 1920) {
                    _this.img_render_type = 'pixelated'
                } else {
                    _this.img_render_type = '-webkit-optimize-contrast'
                }
            }
        },
     

    },
    watch: {
        is_show:function(v1,v2){
            // console.log('vc',v1,v2)
            if(v1){
                /*监测gltf文件是否存在*/
                let end = this.data[0].zips.split('/')[this.data[0].zips.split('/').length-1]
                let url = this.data[0].zips +'/'+end+'.gltf'
                this.checkRemoteResource(this.header+url)
                this.gitf_url = this.header2+url
                // console.log(this.gitf_url)
            }else{
                this.v_3d = false
            }
            if(v1&&this.fangfa(this.data[0].resolutions).length>=3){
                this.fangfa(this.data[0].resolutions)
                this.Resolution =4
            }else if(v1&&this.fangfa(this.data[0].resolutions).length<3) {
                this.Resolution =2
            }
            console.log('this.Resolution:',this.Resolution,this.data[0].zips)
        },
        assets:function(v1,v2){
            console.log(v1.v2)
        }

    },

    mounted() {
        this.test()
        const renderer = this.$refs.renderer;
        console.log(localStorage.getItem('assets'))
        // const box = this.$refs.box.mesh;
        /*renderer.onBeforeRender(() => {
          box.rotation.x += 0.01;
        });*/
    }
}
</script>
<style scoped>
    .cv{
        width: 1rem;
        height: 1rem;
        color: white;
    }
    .setting{

    }

    .down_options{
        font-size: 1.5rem;
        text-align: center;
    }

    .tuijian{
        font-family: 'Nunito', sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        letter-spacing       : 0em;
        font-feature-settings: "kern" on;
        color: #C8C8C8;
    }

.qiehuan{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    height: 100%;
    color: #C8C8C8;
}

.xiabaio{
    display: grid;
    grid-template-rows: 1fr 30px;
}

.xiabaio div{
    text-align: center;
    line-height: 30px;
}

.qiehuan span{
    cursor: pointer;
}

.qiehuan :hover{
    color: #ffffff;
}
._download{
    display: flex;
    justify-content: center;
    align-items: center;
}

.perview_container{
    border-radius:20px 20px 0 0;
    display: grid;
    grid-template-columns: 10% 1fr 10%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.down_btn{
    width: 180px;
}
.down_set{
    width: 38px;
    margin-left: 0px;
    background: #00000000;
    text-align: right;
    line-height: 2px;
    border: 0px;
    cursor:pointer;
}
.down_set_disab{
    width: 38px;
    margin-left: 0px;
    background: #00000000;
    text-align: right;
    line-height: 2px;
    border: 0px;
    
}
.down_set :hover{
    color: #ffffff;
}
.asset_download{
    display: grid;
    grid-template-rows: 1fr 1fr;
    padding: 13px
}
.ename, .downloads{
    /*margin-top: 16px;
    margin-left: 26px;*/
    padding: 0.5rem 2rem;
    font-size: 24px;
    font-family: 'Alibaba Sans';
    font-weight: 500;
    letter-spacing: 0em;
    line-height: normal;
    font-feature-settings: "kern" on;
    color: #C8C8C8;
/*    text-transform: uppercase;*/
}
.downloads{
    font-size: 1rem;
}

.kk{
    display: grid;
    grid-template-rows:1fr 1fr;
/*    padding: 13px;*/
}
/*.asset_msg{
    border-left:1px solid #50505080;
}*/

.kk div{
    /*display: grid;
    grid-template-rows:1fr 1fr;*/
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
}

.asset_size_webp{
    text-align: center;
}

.asset_size_text{
    text-align: center;
    line-height: 20px;
    color: #C8C8C8;
    font-weight: 500;
}


.asset_size_webp img{
    height: 50px;
}

.asset_size{
/*    padding: 13px;*/
    display: grid;
}
.asset_bottom{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 180px  minmax(100px, 1fr) 250px;
    border-top: 1px solid #50505080 ;

}

#PopupOverLay_SF_3z8ee515c60e29a33db8a98ca6b30dacab9911672249764c0b3cd3a2185fb1d3aa{
    color: #c5c6c7 !important;
    background: rgba(11, 12, 16, .5) !important;
    backdrop-filter: blur(30px) !important;
}

.zh_magicformcont {
    color: #c5c6c7 !important;
    background: rgba(11, 12, 16, .5) !important;
    backdrop-filter: blur(30px) !important;
}

.m-2>>>.el-input__wrapper {
    background: #0b0c10;
    box-shadow: 0 0 0 0px #0f0f0f;
    border: 0px;
    width: 140px;
    height: 25px;
    
}
.m-2>>>.el-input__wrapper:hover{
    background: #0b0c10;
    box-shadow: 0 0 0 0px #0f0f0f !important;
    border: 0px;
/*    width: 180px;*/
    height: 25px;
}
.m-2  >>>.el-input__suffix-inner{
    border-left: 2px solid #232323 !important;
}


.fbx {
    margin-top: 1rem;
}


.el-row {
    margin-top: 0.5rem;
}


.m-2>>>.el-input__inner {
    color: #fff;
    font-size: 14px;
    padding-left: 3px;
}

.spinner {
    margin: 100px auto 0;
    width: 150px;
    text-align: center;
}

.windows_content>>>.el-drawer {
    /*    background-color: #171717;*/
}

.file_select {
    color: #fff;
    text-transform: capitalize;
}

.spinner>div {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
    animation: bouncedelay 1.4s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0.0)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes bouncedelay {

    0%,
    80%,
    100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }

    40% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}

.any_btn {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    color: RGB(0, 0, 0);
    font-weight: 700;
    margin-top: 1.5em;
}

.download>>>.el-input__wrapper {
    /*    background: #181818;*/
    box-shadow: 0 0 0 1px #2f2f2f;
    font-size: 1rem;
}




.download>>>.el-select .el-input.is-focus .el-input__wrapper {
    box-shadow: 0 0 0 1px #5e5e5e inset !important;
}

.el-popper.is-light {
    background: #383838 !important;
}

.windows {
    overflow-x: hidden;
    position: fixed;
    inset: 0px;
    z-index: 20;
    background: rgba(11, 12, 16, 0.5);
    backdrop-filter: blur(18px);

}

.windows_content {
    /*background: rgba(11, 12, 16, 0.8);
    border-radius: 20px;*/

    /* backdrop-filter: blur(112px);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
    z-index: 20;*/

}

@media (min-width: 801px) {
    .windows_content {
        position: absolute;
        top: 50%;
        left: 50%;
        max-height: calc(0px + 100vh);
        transform: translate(-50%, -50%);
    }
}

.windows_content_container_top {
    width: 100%;
    position: absolute;
    height: 0;
    min-height: calc(100% - 136px);
    top: 0;
}


@media (min-width: 801px) {
    .windows_content_container_top {
        position: relative;
        height: 800px;
        min-height: unset;
        top: unset;
    }
    
}
@media (max-width: 801px) {
    .asset_size {
        display: none;
    }
    .asset_bottom{
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns:  minmax(100px, 1fr) 250px;
        border-top: 1px solid #50505080 ;

    }
    .perview_container{
        background-size: 100% auto ;
    }
}

.assetFooterContainer___1zaZZ {
    display: flex;
    /*background: #2f2f2f;*/
    width: 100%;
    max-height: 136px;
    border-radius: 0px 0px 6px 6px;
    justify-content: space-between;
    height: 136px;
    position: absolute;
    bottom: 0px;
}

@media (min-width: 801px) {
    .assetFooterContainer___1zaZZ {
        position: relative;
        bottom: unset;
    }
}

.footerlogoutLeft___18MOd {
    border: none;
}

@media (min-width: 1121px) {
    .footerLeft___34t_5 {
        /*border-right: 2px solid #1a1a1a;*/
        width: 66.4%;
    }
}

.footerLeft___34t_5 {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.background___1dGua {
    /*    background: #2F2F2F;*/
}

.assetInfoPopup___3bwHV {
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    border-radius: 6px;
}

.borderRadius___28fHH {
    border-radius: 6px;
}

.infoBlock___22q7u {
    padding: 24px;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
    /*    background: #2F2F2F;*/
    box-sizing: border-box;
}


.borderRadius___1z3VG {
    border-radius: 6px 6px 0 0;
}

.image___24YS3 {
    position: relative;
    display: inline-block;
    opacity: 1;
    background-size: cover;
    width: 100%;
    height: 100%;
    /*    background-color: #1b1b1b;*/
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center center;
}

.pointsWrap___1T7Gj {
    padding: 24px;
}

.pointsWrap___1T7Gj span {
    width: 88px;
    height: 88px;
    padding-top: 14px;
    padding-bottom: 16px;
    font-size: 2.25rem;
    line-height: 36px;
    font-weight: 700;
    border-radius: 6px;
    box-sizing: border-box;
    visibility: hidden;
}

@media (min-width: 1121px) {
    .pointsWrap___1T7Gj span {
        visibility: visible;
    }
}

.points___2P5cf {
    display: inline-block;
    border: 2px solid #6f6f6f;
    border-radius: 5px;
    color: #fff;
    text-align: center;

    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -1px;
    text-transform: uppercase;
}

.points___2P5cf .label___ESlNf {
    display: block;
    width: auto;
    height: auto;
    padding-top: 6px;
    margin: 0 auto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.pointsWrap___1T7Gj span {
    width: 88px;
    height: 88px;
    padding-top: 14px;
    padding-bottom: 16px;
    font-size: 2.25rem;
    line-height: 36px;
    font-weight: 700;
    border-radius: 6px;
    box-sizing: border-box;
    visibility: hidden;
}

@media (min-width: 1121px) {
    .pointsWrap___1T7Gj span {
        visibility: visible;
    }
}

.footerLogoutRight___rXryr {
    height: 136px;
    box-sizing: border-box;
}

.footerRight___2Qst3 {
    display: none;
    flex-direction: column;
    padding: 22px 24px 24px 24px;
}


@media (min-width: 801px) {
    .footerRight___2Qst3 {
        display: flex;
    }
}

.Button___1mkoh {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background 0.25s;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    border: 2px solid #8BC24A;
    color: #000000;
    background: #8BC24A;
    font-family: Roboto, Source Sans Pro, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0.875em 2em;
    cursor: pointer;
    border-radius: 4px;
}


.Button--fullWidth___2subI {
    display: block;
    width: 100%;
    letter-spacing: 1px;
    height: 32px;
    padding: 0;
}

.Button--primary___MHrXD {
    color: black !important;
    background: #26BBFF;
    border-color: #26BBFF;
    transition: background 0.25s, box-shadow 0.25s;
}

.center {
    /* border-right: 1px solid rgba(216, 216, 216, 0.2);
    border-left: 1px solid rgba(216, 216, 216, 0.2);*/
    display:grid;
/*    height: 800px;*/
    grid-template-rows: 1fr 120px;
    /*justify-content: center;
    align-items: center;*/
}

.left {
    /*    max-height:750px ;*/
    overflow: hidden;
    overflow: scroll;
}


.windows_content_container {
    width: 100%;
    height: 800px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 0 minmax(100px, 1fr) ;
    background: #232323;
    border-radius: 20px;
    backdrop-filter: blur(112px);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
    z-index: 20;

}


@media (min-width: 801px) {
    .windows_content_container {
        width: 801px;
        grid-template-rows: 1fr;
        grid-template-columns: 0px minmax(100px, 1fr);
    }

    .right {
        display: none;
    }

    .left {
        overflow: hidden;
    }

}
.latinName {
    cursor: pointer !important;
}


@media (min-width: 1121px) {
    .windows_content_container {
        width: 960px;
        grid-template-rows: 1fr;
        grid-template-columns: 0px minmax(100px, 1fr);
    }

    .center {
        /*border-right: 1px solid rgba(216, 216, 216, 0.2);
        border-left: 0px solid rgba(216, 216, 216, 0.2);*/
    }

    .right {
        padding: 28px 22px;
        box-sizing: border-box;
        display: grid;
        grid-template-rows: minmax(100px, 1fr) 43px;
        grid-template-columns: 1rf;

    }

    .left {
        overflow: hidden;
    }
}

@media (min-width: 1514px) {
    .windows_content_container {
        width: 1080px;
        grid-template-rows: 1fr;
        grid-template-columns: 160px minmax(100px, 1fr);
    }

    .perview_container{
        border-radius:0 20px 0 0;
    }

    .center .setting{
/*        border-right: 1px solid rgba(216, 216, 216, 0.2);*/
        border-left: 1px solid rgba(216, 216, 216, 0.2);
    }

    .right {
        padding: 28px 22px;
        box-sizing: border-box;
        display: grid;
        grid-template-rows: minmax(100px, 1fr) 43px;
        grid-template-columns: 1rf;

    }

    .left {
        display: grid;
    }
}

.windows_content_container_top_container {
    height: 32px;
    width: 100%;
    position: absolute;
    z-index: 10;
}

.image3dView___3hBlu {
    background-size: contain;
}

.borderRadius___1z3VG {
    border-radius: 6px 6px 0 0;
}

.image___24YS3 {
    position: relative;
    display: inline-block;
    opacity: 1;
    background-size: cover;
    width: 100%;
    height: 100%;
    /*    background-color: #1b1b1b;*/
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center center;
}

.image___ko4ag {
    color: white;
    position: relative;
    display: inline-block;
    opacity: 1;
    background-size: contain;
    width: 100%;
    height: 100%;
    /*    background-color: #1b1b1b;*/
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center center;
}

.borderRadius___3cuVG {
    border-radius: 6px 6px 0px 0px;
}

.borderRadius___3cuVG {
    border-radius: 6px 6px 0px 0px;
}

.windows_content_container_top_menu_container {
    position: absolute;
    right: 17px;
    top: 24px;
    display: flex;
    height: 32px;
}

@media (min-width: 801px) {
    .no-side-panel .windows_content_container_top_menu_container_first {
        display: flex;
    }
}

.windows_content_container_top_menu_container_first {
    background: #383838;
    border-radius: 50%;
    position: relative;
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    transition: background 0.25s ease, box-shadow 0.25s ease;
    cursor: pointer;
    box-shadow: none;
}

.windows_close {
    background: #383838;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 32px;
    height: 32px;
    right: 10px;
    top: 10px;
    transition: background 0.25s ease, box-shadow 0.25s ease;
    cursor: pointer;
    box-shadow: none;
}

.windows_preview{
    background: #383838;
    border-radius: 2rem;
    padding: 0px 1rem;
    position: absolute;
    display: flex;
    font-size: 1.25rem;
    color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;
    /*width: 32px;
    height: 32px;*/
    right:60px;
    top: 10px;
/*    border: 1px solid #FFF;*/
    transition: background 0.25s ease, box-shadow 0.25s ease;
    cursor: pointer;
    box-shadow: none;
}

.assetname {
    /*    font-family: Roboto Condensed, Open Sans Condensed, Source Sans Pro, sans-serif;*/
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    text-transform: uppercase;
    color: #c5c6c7;
    max-width: 453px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.popupFooter___-bLPf {
    bottom: 24px;
    /* left: 24px;
    height: 24px;*/
}

.footer_any {
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    z-index: 100;
    height: 27px;
    width: 160px;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.text___3I8ZD {
    font-family: Roboto, Source Sans Pro, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    text-transform: capitalize;
}

.actionButtons___1uTYw {
    width: 100%;
    position: absolute;
    right: 0px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;

    height: 24px;
    align-items: center;
}

.button__hj {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.footerContainer___Bkmyw {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: space-between;
}

.size {
    position: absolute;
    left: 1rem;
    top: 1rem;
    word-wrap: break-word;
    width: 100px;
    text-align: center;
    flex-direction: column;
    align-items: center;
    border-radius: 0.3rem;
    background: rgba(0, 0, 0, 0.5);
    padding: 0.8rem;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    z-index: 3;
    fill: white;
    display: block;
}





.latinName {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 300;
    color: #c5c6c7;
}

.model_top {
    margin-top: 28px;
}

.right>>>.el-radio__inner {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #6236FF;
}

.right>>>.el-radio__label {
    color: #c5c6c7;
    font-size: 16px;
}

.right>>>.el-radio__input.is-checked .el-radio__inner {
    background: linear-gradient(98deg, #9852E8 0%, #1A20B2 98%);
}

.right>>>.el-checkbox__inner {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #6236FF;
}

.right>>>.el-checkbox__input.is-checked .el-checkbox__inner {
    background: linear-gradient(98deg, #9852E8 0%, #1A20B2 98%);
    border: 1px solid #6236FF;
}

.right>>>.el-checkbox__label {
    color: #c5c6c7 !important;
}

.right>>>.el-checkbox {
    height: 24px;
}

.download_btn {
    width: 320.87px;
    height: 43.12px;
    border-radius: 20px;
    border: 0px;
    opacity: 1;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    text-transform: uppercase;
    /* 主要字体色 */
    color: #C5C6C7;
    cursor: pointer;
    /* 按钮渐变 */
    background: linear-gradient(98deg, #9852E8 0%, #1A20B2 98%);
}
</style>